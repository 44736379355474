<template>
  <div v-if="map.show" class="map-container">
    <l-map
      id="map"
      ref="map"
      :zoom="map.zoom"
      :center="map.center"
      :options="map.mapOptions"
      style="height: 80%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @ready="MapReady"
    >
      <l-control-fullscreen />
      <l-tile-layer
        :url="map.url"
        :attribution="map.attribution"
      />
      <template v-for="(m, iMap) in maps">
        <l-marker
          v-if="SetLatLong(m.lead_position)"
          :key="'map-' + iMap"
          :lat-lng="SetLatLong(m.lead_position)"
          :icon="map.iconPusat"
          @click="LoadMap(m.id)"
        >
          <l-popup>
            <div class="popup-data">
              <div class="popup-title">
                {{ m.role + ': ' + m.name }}
              </div>
              <div class="popup-city">
                {{ m.city_name || '-' }}
              </div>
            </div>
          </l-popup>
        </l-marker>
      </template>
      <template v-for="(m, iMap) in maps">
        <l-marker
          v-if="SetLatLong(m.member_position)"
          :key="'map-member-' + iMap"
          :lat-lng="SetLatLong(m.member_position)"
          :icon="map.iconPusat"
          @click="LoadMap(m.member_id)"
        >
          <l-popup>
            <div class="popup-data">
              <div class="popup-title">
                {{ m.member_role + ': ' + m.member_name }}
              </div>
              <div class="popup-city">
                {{ m.member_city_name || '-' }}
              </div>
            </div>
          </l-popup>
        </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup
} from 'vue2-leaflet'
import { latLng, icon } from 'leaflet'
import '@elfalem/leaflet-curve'
import LControlFullscreen from 'vue2-leaflet-fullscreen'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlFullscreen
  },
  props: {
  },
  data: () => ({
    mapRoutes: null,
    map: {
      show: false,
      zoom: 4.5,
      center: latLng(-2.7702655, 124.2948862),
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5
      },
      iconPusat: icon({
        iconUrl: '/images/maps/ic-ebright-skin-marker.png',
        iconSize: [27, 45],
        iconAnchor: [13, 45]
      }),
      icon: icon({
        iconUrl: '/images/maps/ic-ebright-skin-marker-pusat.png',
        iconSize: [27, 45],
        iconAnchor: [13, 45]
      })
    },
    popupLoading: false,
    popupData: [],
    maps: []
  }),
  watch: {
  },
  created () {
    this.LoadMap()
  },
  mounted () {
    this.drawMap()
  },
  methods: {
    LoadMap (uid) {
      const findExistMap = this.maps.find(r => parseInt(r.id) === parseInt(uid))
      if (!findExistMap) {
        this.$store.dispatch('MAP_GET', uid || this.$store.state.user.id).then(res => {
          if (res.status) {
            this.maps = this.maps.concat(res.data.data || [])
            for (let r = 0; r < res.data.data.length; r++) {
              const m = res.data.data[r]
              const posLead = this.SetLatLong(m.lead_position, true)
              const posMember = this.SetLatLong(m.member_position, true)
              if (posLead && posMember) {
                this.$MapCurve(
                  this.$refs.map.mapObject,
                  posLead,
                  posMember,
                  '#c6893f'
                )
              }
            }
          }
        })
      }
    },
    MapReady (map) {
      /* eslint-disable-next-line */
    },
    SetLatLong (latlng, r) {
      const m = latlng ? latlng.split(';') : ''
      if (m.length > 1) {
        const lat = parseFloat(m[0].replace('lat:', ''))
        const lng = parseFloat(m[1].replace('lng:', ''))
        if (!lat || !lng) {
          return null
        }
        if (r) {
          return [lat, lng]
        } else {
          return latLng(lat, lng)
        }
      } else {
        return null
      }
    },
    drawMap () {
      this.map.show = true
    },
    zoomUpdate (zoom) {
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    }
  }
}
</script>

<style lang="scss">
.map-container {
  position: relative;
  border-radius: 10px;
  height: 450px;
  overflow: hidden;
  >.vue2leaflet-map {
    height: 100%!important;
    .leaflet-popup-content {
      width: auto!important;
      margin: 5px 10px;
      .popup-data {
        position: relative;
        min-width: 150px;
        max-height: 110px;
        padding: 5px;
        overflow-y: auto;
        >.popup-title {
          text-transform: capitalize;
          font-weight: 600px;
        }
      }
    }
  }
}
.leaflet-container {
  a {
    &.leaflet-popup-close-button {
      top: 4px;
      right: 4px;
      height: 18px;
    }
  }
}
</style>
